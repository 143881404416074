import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIURL, DEFAULTURL } from '../api/apiDefault';
import { GetAccessToken } from '../api/GetToken';
import { Loading } from '../component/loading';
import billboardStyles from '../css/billboard.module.css';
import styles from '../css/traffic.module.css';

function EachPages(props) {
  const accessToken = GetAccessToken();
  const { pagesInfo, userInfo, resetMap } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [pageName, setPageName] = useState('');

  const [pageItems, setPageItems] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const targetId = searchParams.get('id');
  const scroll_container = useRef();
  const nav_scroll_container = useRef();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  // useEffect(() => {
  //   if (targetId) {
  //     const targetContainer = document.getElementById(targetId);
  //     if (targetContainer) {
  //       const targetScrollTop = targetContainer.offsetTop; // 컨테이너의 상단 위치
  //       const additionalScroll = 100; // 추가로 스크롤할 거리
  //       const targetScrollPosition = targetScrollTop - additionalScroll;
  //       console.log(targetScrollTop);

  //       // scroll_container를 스크롤
  //       console.log(scroll_container.current);
  //       // document.querySelector(".nav_scroll").scrollTo({ left: 0, top: targetScrollTop });
  //       scroll_container.current.scrollTo({
  //         top: targetScrollPosition,
  //         behavior: "smooth",
  //       });
  //       // targetContainer.scrollIntoView({ behavior: "smooth" });
  //       // toTop();
  //     }
  //   }
  // }, [targetId]);

  useEffect(() => {
    if (targetId) {
      const targetContainer = document.getElementById(targetId);
      if (targetContainer) {
        const targetScrollTop = targetContainer.offsetTop; // 컨테이너의 상단 위치
        const additionalScroll = isMobile ? 63 : 100; // 추가로 스크롤할 거리
        const targetScrollPosition = targetScrollTop - additionalScroll;
        console.log(targetScrollTop);
        scroll_container.current.scrollTo({
          top: targetScrollPosition,
          behavior: 'smooth',
        });
        // targetContainer.scrollIntoView({ behavior: 'smooth' })
        // navigate(`/traffic?id=${targetId}`);
      }
    }

    if (!loading) {
      const _target = scroll_container.current;
      const _fn = debounce(() => handleScroll());

      _target.addEventListener('scroll', _fn);
      return () => {
        if (_target) {
          _target.removeEventListener('scroll', _fn);
        }
      };
    }
  }, [resetMap, loading]);

  useEffect(() => {}, [resetMap]);

  const debounce = (fn) => {
    let _timer;
    return (...args) => {
      clearTimeout(_timer);
      _timer = setTimeout(() => {
        fn.apply(this, args);
      }, 100);
    };
  };

  const handleScroll = () => {
    const containers = nav_scroll_container.current?.querySelectorAll('.second_container');
    if (containers) {
      for (const container of containers) {
        const rect = container.getBoundingClientRect();
        if (rect.top <= 210 && rect.bottom >= 210) {
          // 스크롤 이벤트 중에 이미 아이디가 바뀌어 있는지 확인
          const _id = container.getAttribute('id');
          navigate(`${location.pathname}?id=${_id}`);
          break;
        }
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    let categoryId;
    switch (location.pathname) {
      case '/traffic':
        categoryId = 2;
        break;
      case '/shopping':
        categoryId = 3;
        break;
      case '/life':
        categoryId = 4;
        break;
      case '/sport':
        categoryId = 5;
        break;
      case '/etc':
        categoryId = 6;
        break;
      default:
        categoryId = 1;
        break;
    }

    axios.get(`${APIURL}category/list`).then((res) => {
      const currentPage = res.data.filter((item) => item.id === categoryId);
      setPageName(currentPage[0].name);
    });

    axios.get(`${APIURL}media/category/${categoryId}`).then((res) => {
      let _data = res.data.content;

      for (let i = 0; i < _data.length; i++) {
        let mediaList = _data[i].content;
        mediaList.sort((a, b) => a.sort - b.sort);
        for (let j = 0; j < mediaList.length; j++) {
          let depth3OptionList = mediaList[j].option;
          depth3OptionList?.sort((a, b) => a.sort - b.sort);

          let depth4_arr = mediaList[j].content;
          console.log(depth4_arr);
          depth4_arr?.sort((a, b) => a.sort - b.sort);
          for (let k = 0; k < depth4_arr.length; k++) {
            let depth4OptionList = depth4_arr[k].option;
            depth4OptionList?.sort((a, b) => a.sort - b.sort);

            if (depth4_arr[k]?.content?.sort) {
              depth4_arr[k]?.content?.sort((a, b) => a.sort - b.sort);
            }
          }
        }
      }
      console.log(_data);

      setPageItems(res.data.content);
      setLoading(false);
    });

    accessToken &&
      axios
        .get(`${APIURL}favorite/my/media`, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) setWishList([...res.data]);
        })
        .catch((err) => console.log(err));

    toTop();
  }, [location.pathname]);

  useEffect(() => {
    if (!location.search) {
      toTop();
    }
  }, [location.search]);

  const handleWish = (event, id, status) => {
    console.log('wish click');
    event.stopPropagation();
    event.preventDefault();
    console.log(userInfo);
    if (!accessToken) {
      navigate('/login');
      return;
    }

    console.log(event, id, status);
    if (!status) {
      const _data = {
        media_id: id,
      };
      axios
        .post(`${APIURL}favorite`, _data, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setWishList([...wishList, id]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .delete(`${APIURL}favorite/${id}`, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const updatedWishList = wishList.filter((itemId) => itemId !== id);
            setWishList([...updatedWishList]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const [openItemId, setOpenItemId] = useState(null);
  const [planItem, setPlanItem] = useState([]);
  const handleCart = (event, itemId) => {
    event.stopPropagation();
    event.preventDefault();
    if (!accessToken) {
      navigate('/login');
    } else {
      setOpenItemId(itemId);

      axios
        .get(`${APIURL}plan`, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };

  const toTop = () => {
    scroll_container.current?.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
    navigate(`${location.pathname}${location.search}`);
  };

  const itemMouseOver = (e) => {
    e.currentTarget.classList.add(styles.active);
  };

  const itemMouseLeave = (e) => {
    e.currentTarget.classList.remove(styles.active);
  };

  const viewDetail = (id, category_id) => {
    const _path = location.pathname;
    console.log(category_id);
    axios
      .get(`${APIURL}category/one/${category_id}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.depth === 3) {
            navigate(`${_path}Detail?idx=${id}&id=${res.data.id}`, {
              state: { idx: id },
            });
          } else {
            axios
              .get(`${APIURL}category/one/${res.data.parent_id}`)
              .then((res2) => {
                if (res2.status === 200) {
                  navigate(`${_path}Detail?idx=${id}&id=${res2.data.id}`, {
                    state: { idx: id },
                  });
                }
              })
              .catch((err2) => console.log(err2));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="my-container bg-white">
      <div className={`${billboardStyles.main_container}`}>
        <div className={styles.main_container}>
          <div className={`position-relative`}>
            <div className={`${styles.top_bar}`}>
              <div className="d-flex bg-white">
                <div className={`fw-800 d-flex align-items-center ${styles.fs_27px}`}>
                  {pagesInfo
                    .filter((item) => `/${item.id}` === location.pathname)
                    .map((item) => (
                      <React.Fragment key={item.id}>
                        <img
                          src={`${process.env.PUBLIC_URL}${item.src}`}
                          className={styles.icon_size}
                          alt=""
                        />
                        <div>{pageName}</div>
                      </React.Fragment>
                    ))}
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}image/icon/toTop.svg`}
                  alt=""
                  className={`${styles.toTop}`}
                  onClick={toTop}
                />
              </div>
            </div>
            <div className={`${styles.page_container}`}>
              <div
                className={`${styles.scroll_h_container} position-relative scroll nav_scroll`}
                ref={scroll_container}>
                {loading && <Loading />}
                {!loading && (
                  <Row className={styles.content_wrapper} ref={nav_scroll_container}>
                    {pageItems.map((firstItem) =>
                      firstItem.option ? (
                        <RenderListItem
                          key={`first_${firstItem.id}`}
                          itemMouseOver={itemMouseOver}
                          itemMouseLeave={itemMouseLeave}
                          viewDetail={viewDetail}
                          item={firstItem}
                          handleWish={handleWish}
                          handleCart={handleCart}
                          openItemId={openItemId}
                          setOpenItemId={setOpenItemId}
                          planItem={planItem}
                          wishList={wishList}
                          setWishList={setWishList}
                        />
                      ) : (
                        <div key={`first_${firstItem.id}`}>
                          <div className={`${styles.content_title} lh-1`}>{firstItem.title}</div>
                          <Row className={`p-0 ${styles.item_sort}`}>
                            {firstItem.content.map((secondItem) =>
                              secondItem.option ? (
                                <RenderListItem
                                  key={`second_${secondItem.id}`}
                                  itemMouseOver={itemMouseOver}
                                  itemMouseLeave={itemMouseLeave}
                                  viewDetail={viewDetail}
                                  item={secondItem}
                                  handleWish={handleWish}
                                  handleCart={handleCart}
                                  openItemId={openItemId}
                                  setOpenItemId={setOpenItemId}
                                  planItem={planItem}
                                  wishList={wishList}
                                  setWishList={setWishList}
                                />
                              ) : (
                                <div
                                  className="second_container position-relative pe-0"
                                  id={secondItem.id}
                                  key={`second_${secondItem.id}`}>
                                  <div className={`${styles.content_sub_title} main lh-1`}>
                                    {secondItem.title}
                                  </div>
                                  <Row className={styles.item_sort}>
                                    {secondItem.content.map((thirdItem) =>
                                      thirdItem.option ? (
                                        <RenderListItem
                                          key={`third_${thirdItem.id}`}
                                          itemMouseOver={itemMouseOver}
                                          itemMouseLeave={itemMouseLeave}
                                          viewDetail={viewDetail}
                                          item={thirdItem}
                                          handleWish={handleWish}
                                          handleCart={handleCart}
                                          openItemId={openItemId}
                                          setOpenItemId={setOpenItemId}
                                          planItem={planItem}
                                          wishList={wishList}
                                          setWishList={setWishList}
                                        />
                                      ) : (
                                        <div
                                          className="position-relative pe-0"
                                          key={`third_${thirdItem.id}`}>
                                          <div
                                            className={`${styles.content_inner_title} lh-1 fs-12px`}>
                                            {thirdItem.title}
                                          </div>
                                          <Row className={styles.item_sort}>
                                            {thirdItem.content.map((fourthItem) => (
                                              <RenderListItem
                                                key={`fourth_${fourthItem.id}`}
                                                itemMouseOver={itemMouseOver}
                                                itemMouseLeave={itemMouseLeave}
                                                viewDetail={viewDetail}
                                                item={fourthItem}
                                                handleWish={handleWish}
                                                handleCart={handleCart}
                                                openItemId={openItemId}
                                                setOpenItemId={setOpenItemId}
                                                planItem={planItem}
                                                wishList={wishList}
                                                setWishList={setWishList}
                                              />
                                            ))}
                                          </Row>
                                        </div>
                                      ),
                                    )}
                                  </Row>
                                </div>
                              ),
                            )}
                          </Row>
                        </div>
                      ),
                    )}
                  </Row>
                )}

                {isMobile && <div style={{ paddingTop: '50px' }}></div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function RenderListItem(props) {
  const {
    itemMouseOver,
    itemMouseLeave,
    viewDetail,
    item,
    handleWish,
    handleCart,
    openItemId,
    setOpenItemId,
    planItem,
    wishList,
    setWishList,
  } = props;

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const _periods = item.option[0]?.period.split(',') ?? '';
  const _period =
    _periods[0] === '1개월'
      ? '월'
      : _periods[0] === '1주'
      ? '주'
      : _periods[0] === '1일'
      ? '일'
      : _periods[0] === '1회'
      ? '회'
      : _periods[0] ?? '';
  return (
    item.is_show === 1 && (
      <Col className={styles.item_grid} lg={3} xs={6}>
        <div
          className={styles.item_wrapper}
          onMouseOver={itemMouseOver}
          onMouseLeave={itemMouseLeave}
          onClick={() => {
            viewDetail(item.id, item.category_id);
          }}>
          <div className={styles.list_img}>
            <img src={`${DEFAULTURL}image/${item.file_data[0]['content']}`} alt="" />
          </div>

          <div className={`${styles.media_wrapper} d-flex flex-column`}>
            <div className={`${styles.media_name} d-flex gap-1 align-items-center`}>
              <div className="text-ellipsis">{item.media_name}</div>
              <div className={`${styles.icon_wrapper} ms-auto ${isMobile && 'd-none'}`}>
                <img
                  src={
                    wishList.includes(item.id)
                      ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
                      : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
                  }
                  alt=""
                  onClick={(e) => {
                    handleWish(e, item.id, wishList.includes(item.id));
                  }}
                />
              </div>
            </div>
            <div className={`${styles.media_type} text-ellipsis`}>{item.media_type}</div>

            <div
              className={`${styles.media_option} ${styles.media_option_type} ${
                isMobile && 'pt-1'
              } text-ellipsis`}>
              {/* <div>{item.option[0]?.name}</div> */}
              <div>
                {item.option.map((sortItem, sortIndex) => (
                  <div key={'option_sort_' + sortIndex}>
                    {sortItem.sort === 0 && sortItem?.name}
                  </div>
                ))}
              </div>
              {!isMobile && <div>{item.option[0]?.amount?.split(',')[0]}</div>}
            </div>
            <div className={`${styles.option_price} ${styles.option_media_price} text-ellipsis`}>
              {item.option[0]?.price === '별도안내' ? (
                <>별도안내</>
              ) : (
                <>
                  <NumericFormat
                    thousandSeparator={true}
                    value={item.option[0]?.price}
                    className={`${styles.numericFormat} ps-0 fw-800`}
                    prefix={`${_period} `}
                    suffix="원"
                    style={{ width: '80%' }}
                  />
                </>
              )}

              <div className={`${styles.icon_wrapper} ${!isMobile && 'd-none'}`}>
                <img
                  src={
                    wishList.includes(item.id)
                      ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
                      : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
                  }
                  alt=""
                  onClick={(e) => {
                    handleWish(e, item.id, wishList.includes(item.id));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
    )
  );
}

// function RenderListItem(props) {

//   const { itemMouseOver, itemMouseLeave, viewDetail, item, handleWish, handleCart, openItemId, setOpenItemId, planItem } = props;
//   console.log(item)

//   const isDesktopOrLaptop = useMediaQuery({
//     query: "(min-width: 769px)",
//   });
//   const isMobile = useMediaQuery({
//     query: "(max-width: 768px)",
//   });

//   // 새 플랜에 생성하여 담기
//   const newCart = () => {
//     const _header = {
//       headers: {
//         Authorization: GetAccessToken(),
//       },
//     };

//     const _data = {
//       name: `새플랜${planItem.length + 1}`,
//       plan_data: "[]",
//     };
//     axios
//       .post(`${APIURL}plan`, _data, _header)
//       .then(res => {
//         if (res.status === 200) {
//           const _mediaData = {
//             plan_id: res.data.id,
//             // plan_data: JSON.stringify({
//             //   media: Number(id),
//             //   option: selectSet.option_data.id,
//             //   media_name: data.media_name,
//             //   selected_option: {
//             //     selected_option_item: selectSet.option_data.id,
//             //     selected_period: selectSet.period,
//             //     selected_amount: selectSet.quantity,
//             //     selected_price: selectSet.price,
//             //     selected_install_price: selectSet.option_data.install_price,
//             //     start_date: moment(startDate).format("YYYY-MM-DD"),
//             //   },
//             // }),
//           };
//           console.log(_mediaData)
//           axios
//             .post(`${APIURL}plan/media`, _mediaData, _header)
//             .then(res2 => {
//               if (res2.status === 200) {
//                 Swal.fire({
//                   icon: "success",
//                   iconColor: "#971b4d",
//                   text: `새플랜이 생성되었습니다.`,
//                   confirmButtonText: "확인",
//                   confirmButtonColor: "#971b4d",
//                 }).then(() => {

//                 });
//               }
//             })
//             .catch(err2 => console.log(err2));
//         }
//       })
//       .catch(err => console.log(err));
//   };

//   return (
//     <Col className="pb-4" lg={3} xs={6}>
//       <div
//         className={styles.item_wrapper}
//         onMouseOver={itemMouseOver}
//         onMouseLeave={itemMouseLeave}
//         onClick={() => {
//           viewDetail(item.id);
//         }}
//       >
//         <div className={styles.list_img}>
//           <img
//             src={`http://115.85.180.225/image/${item.file_data[0]['content']}`}
//           />
//         </div>

//         <div className="px-2 py-2">
//           <div>
//             <div className="fs-13px fw-800 pb-1 d-flex gap-1">
//               <div>{item.media_name}</div>
//               <div className={`${styles.icon_wrapper} ms-auto ${isMobile && "d-none"}`}>
//                 <img
//                   src={
//                     item.wish
//                       ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
//                       : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
//                   }
//                   alt=""
//                   onClick={(e) => {
//                     handleWish(
//                       e
//                       // "wish",
//                       // "outdoor",
//                       // item.target,
//                       // listItem.listTitle,
//                       // content.seq
//                     );
//                   }}
//                 />
//               </div>
//               <div className={`${styles.icon_wrapper} ${isMobile && "d-none"} position-relative`}>
//                 <img
//                   src={
//                     item.cart
//                       ? `${process.env.PUBLIC_URL}image/icon/cartVariant.svg`
//                       : `${process.env.PUBLIC_URL}image/icon/cartDefault.svg`
//                   }
//                   onClick={(e) => {
//                     handleCart(e, item.id);
//                   }}
//                 />
//                 {openItemId === item.id && (
//                   <div className={`${styles.cart_wrapper} end-0 mt-2`} onClick={(event) => {
//                     event.stopPropagation();
//                     event.preventDefault();
//                   }}>
//                     <div className="d-flex align-items-center">
//                       <div className="fs-10px fw-700">아래 플랜에 매체 담기</div>
//                       <IoMdClose
//                         className="ms-auto pointer"
//                         size="24"
//                         onClick={() => setOpenItemId(null)}
//                       />
//                     </div>
//                     {planItem.length > 0 && (
//                       <div className="fs-7px fw-700 my-3 d-flex flex-column" style={{ gap: "3px 0" }}>
//                         {planItem.map(item => (
//                           <div key={item.id}>
//                             {item.name}
//                           </div>
//                         ))}
//                       </div>
//                     )}
//                     <div className="fs-7px fw-700" onClick={newCart}>+ 새 플랜을 생성하여 담기</div>
//                   </div>
//                 )}

//               </div>

//             </div>
//             <div className="fs-7px">{item.media_type}</div>

//             <div className="fs-7px d-flex gap-2 flex-wrap">
//               <div>{item.option[0].name}</div>
//               <div>{item.option[0].amount?.split(",")[0]}</div>
//             </div>
//             <div className="fs-13px fw-800 d-flex gap-1">
//               {item.option[0].price === "별도안내" ? (
//                 <>별도안내</>
//               ) : (
//                 <>
//                   <NumericFormat
//                     thousandSeparator={true}
//                     value={item.option[0].price}
//                     disabled={true}
//                     className="border-0 fs-13px fw-800 pointer text-dark"
//                     prefix="월"
//                     suffix="원"
//                     style={{ width: "80%" }}
//                   />
//                 </>
//               )}

//               <div className={`${styles.icon_wrapper} ms-auto ${!isMobile && "d-none"}`}>
//                 <img
//                   src={
//                     item.wish
//                       ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
//                       : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
//                   }
//                   alt=""
//                   onClick={(e) => {
//                     handleWish(
//                       e
//                       // "wish",
//                       // "outdoor",
//                       // item.target,
//                       // listItem.listTitle,
//                       // content.seq
//                     );
//                   }}
//                 />
//               </div>
//               <div className={`${styles.icon_wrapper} ${!isMobile && "d-none"}`}>
//                 <img
//                   src={
//                     item.cart
//                       ? `${process.env.PUBLIC_URL}image/icon/cartVariant.svg`
//                       : `${process.env.PUBLIC_URL}image/icon/cartDefault.svg`
//                   }
//                   alt=""
//                   onClick={(e) => {
//                     handleCart(e);
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Col>
//   );
// }

export default EachPages;
