import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import { APIURL, DEFAULTURL } from './api/apiDefault';
import { GetAccessToken } from './api/GetToken';

const Nav = (props) => {
  const { homeStyle, number } = props;
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 769px)' });
  const navigate = useNavigate();
  const isTablet = useMediaQuery({
    query: '(min-width:769px) and (max-width:1023px)',
  });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const COOKIE = GetAccessToken();

  const searchSubmit = (e) => {
    if (e.key === 'Enter' && search !== '') {
      navigate(`/search?v=${search}`);
    }
  };
  const [search, setSearch] = useState('');

  const initGuideModal = {
    show: false,
  };

  const [guideModal, setGuideModal] = useState(initGuideModal);
  const guideOpen = () => {
    setGuideModal({
      ...guideModal,
      show: true,
    });
  };

  const modalOpen = (_title) => {
    const _api = `${APIURL}intro`;

    axios
      .get(_api)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${DEFAULTURL}image/site/${res.data.content}`, '_blank');
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(`${APIURL}guide`)
      .then((res) => {
        const _data = res.data;
        setGuideModal({
          ...guideModal,
          ..._data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      {isDesktopOrLaptop && (
        <div className={`nav-bar ${homeStyle}`}>
          <div className="nav-bar-max d-flex flex-wrap align-items-end justify-content-between mx-auto">
            <Link to="/" className="pointer">
              <img
                src={`${process.env.PUBLIC_URL}/image/icon/logo_white.svg`}
                style={{ width: '105px', height: '30px' }}
                alt=""
              />
            </Link>
            <div className="nav-right-box d-flex align-items-center">
              <div className="nav-search-box d-flex align-items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/image/icon/search_gray.svg`}
                  style={{ width: '14px', height: '16px' }}
                  alt=""
                />
                <input
                  type="text"
                  onKeyDown={searchSubmit}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  className="nav-search-input"
                />
              </div>
              <Link to="/search" className="d-flex align-items-center pointer">
                <img
                  src={`${process.env.PUBLIC_URL}/image/icon/search_nav.svg`}
                  className="nav-icons"
                  alt=""
                />
                <div className="nav-font text-nowrap nav-text-hover">상세검색</div>
              </Link>
              <div className="d-flex align-items-center pointer" onClick={modalOpen}>
                <img
                  src={`${process.env.PUBLIC_URL}/image/icon/guide_nav.svg`}
                  className="nav-icons"
                  alt=""
                />
                <div className="nav-font text-nowrap nav-text-hover">회사 소개서</div>
              </div>
              {/* <Link to={COOKIE ? '/wish' : '/login'} className="d-flex align-items-center pointer">
                <img src={`${process.env.PUBLIC_URL}/image/icon/heart.svg`} className="nav-icons" />
                <div className="nav-font text-nowrap nav-text-hover">찜리스트</div>
              </Link> */}
              <Link
                to={COOKIE ? '/mediaCart' : '/login'}
                className="d-flex align-items-center pointer">
                {COOKIE && number.cart > 0 && <div className="nav-count">{number.cart}</div>}
                {(!COOKIE || number.cart === 0) && (
                  <img
                    src={`${process.env.PUBLIC_URL}/image/icon/cart_nav.svg`}
                    className="nav-icons"
                    style={{ width: '21px' }}
                    alt=""
                  />
                )}
                <div className="nav-font text-nowrap nav-text-hover">미디어 카트</div>
              </Link>
              <Link
                to={COOKIE ? '/mypage' : '/login'}
                className="d-flex align-items-center pointer">
                {COOKIE && number.mypage > 0 && <div className="nav-count">{number.mypage}</div>}
                {(!COOKIE || number.mypage === 0) && (
                  <img
                    src={`${process.env.PUBLIC_URL}/image/icon/mypage_nav.svg`}
                    className="nav-icons"
                    alt=""
                  />
                )}
                <div className="nav-font text-nowrap nav-text-hover">마이페이지</div>
              </Link>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div
          className={`nav-bar-mobile d-flex align-items-end justify-content-between ${homeStyle}`}>
          <Link to="/" className="me-4">
            <img
              src={`${process.env.PUBLIC_URL}/image/icon/logo_white.svg`}
              style={{ width: '80px', marginBottom: '3px' }}
              alt=""
            />
          </Link>
          <div className="d-flex" style={{ gap: '0 0.8rem' }}>
            <Link to="/search" className="text-center">
              <img
                src={`${process.env.PUBLIC_URL}/image/icon/search_m.svg`}
                className="nav-icons"
                alt=""
              />
              <div className="fs-10px text-white fw-700 word-keep-all">상세 검색</div>
            </Link>
            <div className="text-center" onClick={modalOpen}>
              <img
                src={`${process.env.PUBLIC_URL}/image/icon/guide_nav.svg`}
                className="nav-icons"
                alt=""
              />
              <div className="nav-font text-nowrap nav-text-hover">회사 소개서</div>
            </div>
            {/* <Link to={COOKIE ? '/wish' : '/login'} className="text-center">
              <img src={`${process.env.PUBLIC_URL}/image/icon/heart.svg`} />
              <div className="fs-10px text-white fw-700 word-keep-all">찜 리스트</div>
            </Link> */}
            <Link to={COOKIE ? '/mediaCart' : '/login'} className="text-center">
              <img
                src={`${process.env.PUBLIC_URL}/image/icon/cart_m.svg`}
                className="nav-icons"
                alt=""
              />
              <div className="fs-10px text-white fw-700 word-keep-all">미디어 카트</div>
            </Link>
            <Link to={COOKIE ? '/mypage' : '/login'} className="text-center">
              <img
                src={`${process.env.PUBLIC_URL}/image/icon/mypage_m.svg`}
                className="nav-icons"
                alt=""
              />
              <div className="fs-10px text-white fw-700 word-keep-all">마이페이지</div>
            </Link>
          </div>
        </div>
      )}

      <Modal
        show={guideModal.show}
        onHide={() => setGuideModal({ ...guideModal, show: false })}
        centered>
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <div className="fs-18px fw-800 main">이용 가이드</div>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="media" className="mb-3 guide_tap mt-2" fill>
            <Tab eventKey="media" title="매체를 찾는 방법" className="bg-transparent text-dark">
              <div
                style={{ height: '60vh', overflowY: 'auto' }}
                className={`scroll fs-11px`}
                dangerouslySetInnerHTML={{ __html: guideModal.media }}></div>
            </Tab>
            <Tab eventKey="plan" title="플랜을 관리하는 방법" className="bg-transparent text-dark">
              <div
                style={{ height: '60vh', overflowY: 'auto' }}
                className={`scroll fs-11px`}
                dangerouslySetInnerHTML={{ __html: guideModal.plan }}></div>
            </Tab>
            <Tab eventKey="faq" title="FAQ" className="bg-transparent text-dark">
              <div
                style={{ height: '60vh', overflowY: 'auto' }}
                className={`scroll fs-11px`}
                dangerouslySetInnerHTML={{ __html: guideModal.faq }}></div>
            </Tab>
          </Tabs>
          {/* <div
            className="scroll"
            style={{ maxHeight: "60vh", overflowY: "auto" }}
          >

          </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Nav;
