import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './pages/login/login';
import SignUp from './pages/login/signUp';
import Pages from './pages/pages';
import SiteGuide from './pages/siteGuide';
import ProtectedRoute from './protectedRoute';

function App() {
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  if (typeof window !== 'undefined' && window !== 'null') {
    window.addEventListener('resize', setScreenSize);
  }

  // 우클릭 방지 이벤트 핸들러 추가
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      return false;
    };

    // 문서 전체에 이벤트 리스너 등록
    document.addEventListener('contextmenu', handleContextMenu);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/" element={<Pages />}></Route>
          <Route path="/billboard" element={<Pages />}></Route>
          <Route path="/traffic" element={<Pages />}></Route>
          <Route path="/shopping" element={<Pages />}></Route>
          <Route path="/life" element={<Pages />}></Route>
          <Route path="/sport" element={<Pages />}></Route>
          <Route path="/etc" element={<Pages />}></Route>

          {/* Detail 페이지들에 로그인 보호 추가 */}
          <Route
            path="/trafficDetail"
            element={
              <ProtectedRoute>
                <Pages />
              </ProtectedRoute>
            }></Route>
          <Route
            path="/shoppingDetail"
            element={
              <ProtectedRoute>
                <Pages />
              </ProtectedRoute>
            }></Route>
          <Route
            path="/lifeDetail"
            element={
              <ProtectedRoute>
                <Pages />
              </ProtectedRoute>
            }></Route>
          <Route
            path="/sportDetail"
            element={
              <ProtectedRoute>
                <Pages />
              </ProtectedRoute>
            }></Route>
          <Route
            path="/etcDetail"
            element={
              <ProtectedRoute>
                <Pages />
              </ProtectedRoute>
            }></Route>
          <Route
            path="/billboardDetail"
            element={
              <ProtectedRoute>
                <Pages />
              </ProtectedRoute>
            }></Route>
          <Route
            path="/mediaCart"
            element={
              <ProtectedRoute>
                <Pages />
              </ProtectedRoute>
            }></Route>

          {/* <Route path="/trafficDetail" element={<Pages />}></Route>
          <Route path="/shoppingDetail" element={<Pages />}></Route>
          <Route path="/lifeDetail" element={<Pages />}></Route>
          <Route path="/sportDetail" element={<Pages />}></Route>
          <Route path="/etcDetail" element={<Pages />}></Route>
          <Route path="/billboardDetail" element={<Pages />}></Route>
          <Route path="/mediaCart" element={<Pages />}></Route> */}

          <Route path="/search" element={<Pages />}></Route>
          <Route path="/mypage" element={<Pages />}></Route>
          <Route path="/wish" element={<Pages />}></Route>
          <Route path="/media" element={<Pages />}></Route>
          <Route path="/siteGuide" element={<SiteGuide />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
