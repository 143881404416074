import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIURL } from './api/apiDefault';
import { getCookieToken } from './storage/Cookie';

// 로그인 필요한 페이지 접근 시 처리하는 컴포넌트
const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const accessToken = getCookieToken();

    const config = {
      headers: {
        Authorization: accessToken,
      },
    };

    axios
      .post(`${APIURL}user/me`, '', config)
      .then((res) => {
        if (res.status === 200) {
          const myInfo = res.data;
          if (myInfo.role === 'SUBUSER') {
            alert('광고주 회원만 이용 가능한 메뉴입니다');
            navigate('/signup', { replace: true });
          } else {
            setIsAuthorized(true);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        const pathname = location.pathname;
        const isDetail = /Detail$/.test(pathname);
        if (isDetail) {
          navigate('/login', { replace: true });
        } else {
          navigate('/signup', { replace: true });
        }
        setIsLoading(false);
      });
  }, [navigate]);

  // 로딩 중이면 로딩 표시를 렌더링
  if (isLoading) {
    return <div></div>;
  }

  // 인증이 완료되면 children 반환
  return isAuthorized ? children : null;
};

export default ProtectedRoute;
