import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetAccessToken } from '../../api/GetToken';
import { DEFAULTURL } from '../../api/apiDefault';
import {
  DelWishList,
  GetBillboard,
  GetMarker,
  GetWishList,
  SetWishList,
} from '../../api/billboardApi';
import styles from '../../css/billboard.module.css';
import trafficStyls from '../../css/traffic.module.css';
import { NaverMap } from './naverMap';

function Billboard({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const mediaAreaRef = useRef();
  const mediaRef = useRef({});

  const { resetMap, setResetMap } = props;

  const defaultLocation = [37.5068799, 127.0117769];

  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [mediaData, setMediaData] = useState();
  const [listData, setListData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [defaultZoomLevel, setDefaultZoomLevel] = useState(12);
  const [defaultLatLng, setDefaultLatLng] = useState(null);
  const [clickedDetail, setClickedDetail] = useState();
  const [wishList, setWishList] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [mapBounds, setMapBounds] = useState();
  const [closeInfoWindow, setCloseInfoWindow] = useState(false);

  const accessToken = GetAccessToken();

  function wishHandler(e, index, id, type) {
    e.stopPropagation();

    if (accessToken) {
      if (type === 'add') {
        SetWishList({
          accessToken: accessToken,
          mediaId: id,
        });
        setWishList([...wishList, id]);
      } else if (type === 'delete') {
        DelWishList({
          accessToken: accessToken,
          mediaId: id,
        });
        setWishList(wishList.filter((item) => item !== id));
      }
    } else {
      navigate('/login');
    }

    let _data = detailData;
    _data[index] = {
      ..._data[index],
      wish: !_data[index]['wish'],
    };

    setDetailData([..._data]);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setSelectedCategoryId(queryParams.get('id') ?? '1');
    setClickedDetail();
  }, [location.search]);

  useMemo(() => {
    if (accessToken) {
      GetWishList(accessToken)
        .then((res) => {
          const _resData = res.data.length > 0 ? res.data : [];
          console.log(_resData);
          const wishListId = [..._resData.map((_item) => _item.media_id)];

          setWishList([...wishListId]);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    GetBillboard('1')
      .then((res) => {
        if (res.status === 200) {
          let _listData = [];

          res.data.forEach((item) => {
            _listData.push({
              ...item,
              checke: false,
            });
          });

          _listData = _listData.sort((a, b) => a.sort - b.sort);

          setListData(_listData);

          const queryParams = new URLSearchParams(location.search);
          const _id = queryParams.get('id') ?? '1';
          const _data = _listData.filter((_item) => _item.id === parseInt(_id));

          if (_id === '1') {
            setDefaultZoomLevel(12);
            setDefaultLatLng([defaultLocation[0], defaultLocation[1]]);
          } else if (_data[0]) {
            setDefaultZoomLevel(15);
            setDefaultLatLng([Number(_data[0]['lat']), Number(_data[0]['lon'])]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    GetMarker('1')
      .then((res) => {
        let _data = res.data;
        _data = _data.sort((a, b) => a.sort - b.sort);
        setMediaData([..._data]);
      })
      .catch((error) => {
        console.log(error);
      });

    setResetMap(0);
  }, [null, accessToken]);

  useEffect(() => {
    moveToTopMediaRef();

    const queryParams = new URLSearchParams(location.search);
    const _id = queryParams.get('id') ?? '1';
    const _data = listData.filter((_item) => _item.id === parseInt(_id));

    if (_id === '1') {
      setDefaultZoomLevel(12);
      setDefaultLatLng([defaultLocation[0], defaultLocation[1]]);
    } else if (_data[0]) {
      setDefaultZoomLevel(15);
      setDefaultLatLng([Number(_data[0]['lat']), Number(_data[0]['lon'])]);
    }
  }, [resetMap]);

  useEffect(() => {
    if (mediaData) {
      setDetailData(
        mediaData.map((gItem) => {
          const _imgData = gItem.file_data.filter((_item) => _item.title === '메인 이미지');
          const _img = _imgData[0]?.content;

          const _videoFile = gItem.file_data.filter((_item) => _item.type === 'video_file');
          const _video = _videoFile[0]?.content;

          // sort to item option
          gItem.option.sort((a, b) => a.sort - b.sort);

          const _periods = gItem.option[0]?.period.split(',') ?? '';
          const _period =
            _periods[0] === '1개월'
              ? '월'
              : _periods[0] === '1주'
              ? '주'
              : _periods[0] === '1일'
              ? '일'
              : _periods[0] === '1회'
              ? '회'
              : _periods[0] ?? '';

          let gItemQuantity;
          if (gItem.option.length > 0) {
            gItemQuantity = gItem.option
              .filter((item) => item.sort === 0)
              .map((item) => item.name)[0];
          } else {
            gItemQuantity = '';
          }

          return {
            id: gItem.id,
            categoryId: gItem.category_id,
            img: _img,
            video: _video,
            name: gItem.media_name,
            method: gItem.media_type,
            size: gItem.standard ?? '',
            quantity: gItemQuantity,
            period: _period,
            cost: gItem.option[0]?.price ?? '',
            wish: wishList.length > 0 ? wishList.includes(gItem.id) : false,
            // cart: cartList.length > 0 ? cartList.includes(gItem.id) : false,
            cart: false,
            checked: false,
            defaultLatLng: [gItem.lat, gItem.lon],
            show: gItem.category_id === parseInt(selectedCategoryId) ? true : false,
          };
        }),
      );
    }
  }, [mediaData, selectedCategoryId, wishList]);

  const listCheck = (id) => {
    const updatedList = listData.map((item) => ({
      ...item,
      checked: item.id === id,
    }));
    setListData(updatedList);

    const updatedData = detailData.map((item) => ({
      ...item,
      checked: item.id === id,
    }));
    setDetailData(updatedData);
  };

  // useEffect(()=>{
  //   console.log(detailData);
  // },[detailData])

  function moveToMediaRef(id) {
    mediaRef.current[id]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  function moveToTopMediaRef() {
    mediaAreaRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const [isMobile, setIsMobile] = useState(useMediaQuery({ query: '(max-width: 1280px)' }));

  const handleResize = () => {
    setIsMobile(window.innerWidth > 1280 ? false : true);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    // <div className="rialto-container">
    //   <Nav />
    //   <div className="rialto-content-box d-flex">
    //     <Aside />
    <div className="my-container bg-white">
      <div className={styles.main_container}>
        <div className={`${styles.map_container} position-relative`}>
          <NaverMap
            accessToken={accessToken}
            defaultLocation={defaultLocation}
            mediaData={mediaData}
            wishList={wishList}
            setWishList={setWishList}
            wishHandler={wishHandler}
            cartList={cartList}
            defaultZoomLevel={defaultZoomLevel}
            setDefaultZoomLevel={setDefaultZoomLevel}
            defaultLatLng={defaultLatLng}
            setDefaultLatLng={setDefaultLatLng}
            clickedDetail={clickedDetail}
            setClickedDetail={setClickedDetail}
            listCheck={listCheck}
            moveToMediaRef={moveToMediaRef}
            selectedCategoryId={selectedCategoryId}
            listData={listData}
            setMapBounds={setMapBounds}
            closeInfoWindow={closeInfoWindow}
            resetMap={resetMap}
            setResetMap={setResetMap}
          />
        </div>
        {isMobile ? (
          //모바일인 경우
          <div
            ref={(el) => {
              mediaAreaRef.current = el;
            }}
            className={`scroll ${styles.mobile_items_wrapper}`}>
            <div className="d-flex px-3">
              {selectedCategoryId !== '1' &&
                detailData.map((item, index) => (
                  <DetailItems
                    isMobile={isMobile}
                    key={`detail_${item.id}_${index}`}
                    ref={(el) => {
                      mediaRef.current[parseInt(item.id)] = el;
                    }}
                    index={index}
                    item={item}
                    setDefaultLatLng={setDefaultLatLng}
                    setClickedDetail={setClickedDetail}
                    wishHandler={wishHandler}
                    onMouseOver={() => {
                      listCheck(item.id);
                      setClickedDetail({
                        id: item.id,
                        type: 'hover',
                        lat: item.defaultLatLng[0],
                        lng: item.defaultLatLng[1],
                      });
                    }}
                    navigate={navigate}
                  />
                ))}
            </div>
          </div>
        ) : (
          //모바일 아닌 경우
          <div className={styles.billboard_list_wrapper}>
            <div
              ref={(el) => {
                mediaAreaRef.current = el;
              }}
              className={`${styles.billboard_list_scroll} scroll`}>
              {selectedCategoryId === '1'
                ? listData.map((item, idx) => (
                    <div className="pb-2" key={item.id + idx}>
                      <ItemList
                        item={item}
                        setDefaultLatLng={setDefaultLatLng}
                        onMouseOver={() => {
                          listCheck(item.id);
                        }}
                        onClick={() => {
                          setResetMap(resetMap + 1);
                          navigate(`/billboard?id=${item.id}`);
                        }}
                      />
                    </div>
                  ))
                : detailData.map((item, index) => (
                    <DetailItems
                      isMobile={isMobile}
                      key={`detail_${item.id}_${index}`}
                      ref={(el) => {
                        mediaRef.current[parseInt(item.id)] = el;
                      }}
                      index={index}
                      item={item}
                      setDefaultLatLng={setDefaultLatLng}
                      setClickedDetail={setClickedDetail}
                      wishHandler={wishHandler}
                      onMouseOver={() => {
                        const _lat = Number(item.defaultLatLng[0]);
                        const _lng = Number(item.defaultLatLng[1]);

                        if (
                          mapBounds.min._lat < _lat &&
                          _lat < mapBounds.max._lat &&
                          mapBounds.min._lng < _lng &&
                          _lng < mapBounds.max._lng
                        ) {
                          listCheck(item.id);
                          setClickedDetail({
                            id: item.id,
                            type: 'hover',
                            lat: item.defaultLatLng[0],
                            lng: item.defaultLatLng[1],
                          });
                          setCloseInfoWindow(false);
                        } else {
                          setCloseInfoWindow(true);
                        }
                      }}
                      navigate={navigate}
                    />
                  ))}
            </div>
          </div>
        )}
      </div>
    </div>
    //   </div>
    // </div>
  );
}

function ItemList(props) {
  const { item, onMouseOver, onClick, setDefaultLatLng } = props;
  const [showVideo, setShowVideo] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const imgWrapperRef = useRef(null);
  const videoRef = useRef(null);
  const _image = item.img;
  const _imageUrl = `${DEFAULTURL}image/${_image}`;
  const _video = item.video;
  const _videoUrl = _video && _video.trim() !== '' ? `${DEFAULTURL}image/${_video}` : null;

  // 컴포넌트 마운트 시 비디오 요소 체크
  useEffect(() => {
    // 비디오 요소 참조 정리
    let checkVideoRef;
    let timeout;

    if (_video && _videoUrl) {
      // 비디오 요소가 로드될 때까지 기다리는 안전장치
      checkVideoRef = setInterval(() => {
        if (videoRef.current) {
          clearInterval(checkVideoRef);
        }
      }, 100);

      // 5초 후에는 어쨌든 정리
      timeout = setTimeout(() => {
        clearInterval(checkVideoRef);
      }, 5000);
    }

    return () => {
      if (checkVideoRef) clearInterval(checkVideoRef);
      if (timeout) clearTimeout(timeout);
    };
  }, [_video, _videoUrl]);

  // 이미지 컨테이너에 대한 마우스 오버 핸들러 (비디오 포함)
  const handleWrapperMouseOver = useCallback(
    (e) => {
      e.stopPropagation(); // 이벤트 버블링 방지

      if (_video && _videoUrl) {
        setShowVideo(true);

        // 약간의 지연 추가 (DOM 업데이트 후 실행되도록)
        setTimeout(() => {
          if (videoRef.current) {
            // 자동 재생을 위한 설정
            videoRef.current.muted = true;
            videoRef.current.playsInline = true;

            // 비디오 재생 시 Promise를 사용하여 에러 처리
            const playPromise = videoRef.current.play();

            if (playPromise !== undefined) {
              playPromise.catch((error) => {
                console.error('비디오 재생 에러:', error);
                // 자동 재생 실패 시 수동으로 다시 시도
                if (videoRef.current) {
                  videoRef.current.muted = true;
                  videoRef.current.play().catch((e) => {
                    // 심각한 에러만 로깅
                    if (e.name !== 'AbortError') {
                      console.error('비디오 재생 재시도 실패');
                    }
                  });
                }
              });
            }
          }
        }, 50);
      }

      // 원래의 onMouseOver 이벤트도 호출
      onMouseOver && onMouseOver(e);
    },
    [_video, _videoUrl, onMouseOver],
  );

  // 이미지 컨테이너에 대한 마우스 아웃 핸들러
  const handleWrapperMouseOut = useCallback(
    (e) => {
      e.stopPropagation(); // 이벤트 버블링 방지

      if (_video && videoRef.current) {
        setShowVideo(false);
        try {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        } catch (error) {
          console.error('비디오 정지 중 오류:', error);
        }
      }
    },
    [_video],
  );

  return (
    <div
      className={`${styles.items} ${item.checked ? styles.checked : ''} pointer fw-800`}
      onClick={onClick}>
      <div
        ref={imgWrapperRef}
        className={styles.img_wrapper}
        onMouseOver={handleWrapperMouseOver}
        onMouseOut={handleWrapperMouseOut}>
        {_image && <img src={_imageUrl} alt="" />}
        {_video && _videoUrl && (
          <video
            ref={videoRef}
            src={_videoUrl}
            muted
            playsInline
            autoPlay={showVideo}
            preload="auto"
            loop={true}
            className={showVideo ? styles.show : ''}
            onLoadedData={() => setVideoLoaded(true)}
            onError={(e) => console.error('비디오 로드 에러:', e.target.error)}
          />
        )}
      </div>
      <div className={`${styles.infoBox} ${styles.w_auto}`}>
        <div className="fs-14px">{item.name}</div>
        <div className={styles.info_small}>
          {item.item.map((_item, index) => (
            <div key={`${_item.name}_${index}`} className="fs-9px mb-1">
              {_item.name} ({_item.count})
            </div>
          ))}
        </div>
        <div
          className={'mt-auto fs-9px ' + styles.hoverUnderline}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setDefaultLatLng([item.lat, item.lon]);
          }}>
          위치 확인하기
        </div>
      </div>
    </div>
  );
}

const DetailItems = forwardRef((props, ref) => {
  const {
    index,
    item,
    onMouseOver,
    navigate,
    setDefaultLatLng,
    setClickedDetail,
    wishHandler,
    cartHandler,
    isMobile,
  } = props;
  const [showVideo, setShowVideo] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const imgWrapperRef = useRef(null);
  const videoRef = useRef(null);
  const _image = item.img;
  const _imageUrl = `${DEFAULTURL}image/${_image}`;
  const _video = item.video;
  const _videoUrl = _video && _video.trim() !== '' ? `${DEFAULTURL}image/${_video}` : null;

  // 컴포넌트 마운트 시 비디오 요소 체크
  useEffect(() => {
    // 비디오 요소 참조 정리
    let checkVideoRef;
    let timeout;

    if (_video && _videoUrl) {
      // 비디오 요소가 로드될 때까지 기다리는 안전장치
      checkVideoRef = setInterval(() => {
        if (videoRef.current) {
          clearInterval(checkVideoRef);
        }
      }, 100);

      // 5초 후에는 어쨌든 정리
      timeout = setTimeout(() => {
        clearInterval(checkVideoRef);
      }, 5000);
    }

    return () => {
      if (checkVideoRef) clearInterval(checkVideoRef);
      if (timeout) clearTimeout(timeout);
    };
  }, [_video, _videoUrl]);

  // 이미지 컨테이너에 대한 마우스 오버 핸들러 (비디오 포함)
  const handleWrapperMouseOver = useCallback(
    (e) => {
      e.stopPropagation(); // 이벤트 버블링 방지

      if (_video && _videoUrl) {
        setShowVideo(true);

        // 약간의 지연 추가 (DOM 업데이트 후 실행되도록)
        setTimeout(() => {
          if (videoRef.current) {
            // 자동 재생을 위한 설정
            videoRef.current.muted = true;
            videoRef.current.playsInline = true;

            // 비디오 재생 시 Promise를 사용하여 에러 처리
            const playPromise = videoRef.current.play();

            if (playPromise !== undefined) {
              playPromise.catch((error) => {
                console.error('DetailItems - 비디오 재생 에러:', error);
                // 자동 재생 실패 시 수동으로 다시 시도
                if (videoRef.current) {
                  videoRef.current.muted = true;
                  videoRef.current.play().catch((e) => {
                    // 심각한 에러만 로깅
                    if (e.name !== 'AbortError') {
                      console.error('DetailItems - 비디오 재생 재시도 실패');
                    }
                  });
                }
              });
            }
          }
        }, 50);
      }

      // 원래의 onMouseOver 이벤트도 호출
      onMouseOver && onMouseOver(e);
    },
    [_video, _videoUrl, onMouseOver],
  );

  // 이미지 컨테이너에 대한 마우스 아웃 핸들러
  const handleWrapperMouseOut = useCallback(
    (e) => {
      e.stopPropagation(); // 이벤트 버블링 방지

      if (_video && videoRef.current) {
        setShowVideo(false);
        try {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        } catch (error) {
          console.error('DetailItems - 비디오 정지 중 오류:', error);
        }
      }
    },
    [_video],
  );

  return item.show ? (
    <div className={isMobile ? '' : 'pb-2'}>
      <div
        ref={ref}
        className={`${styles.items} ${item.checked ? styles.checked : ''} pointer ${
          styles.items_height
        }`}
        onClick={() => {
          navigate(`/billboardDetail?id=${item.categoryId}&idx=${item.id}`);
        }}>
        <div
          ref={imgWrapperRef}
          className={styles.img_wrapper}
          onMouseOver={handleWrapperMouseOver}
          onMouseOut={handleWrapperMouseOut}>
          {_image && <img src={_imageUrl} alt="" />}
          {_video && _videoUrl && (
            <video
              ref={videoRef}
              src={_videoUrl}
              muted
              playsInline
              autoPlay={showVideo}
              preload="auto"
              loop={true}
              className={showVideo ? styles.show : ''}
              onLoadedData={() => setVideoLoaded(true)}
              onError={(e) => console.error('DetailItems - 비디오 로드 에러:', e.target.error)}
            />
          )}
        </div>
        <div className={`${styles.infoBox} ${styles.w_auto}`}>
          {item.name && (
            <p className={`${trafficStyls.fs_18px} fw-800 mb-0 text-truncate`}>{item.name}</p>
          )}
          {!isMobile && (
            <>
              {item.method && (
                <p className={`${trafficStyls.pfs_9px} fw-700 mb-0 text-truncate`}>{item.method}</p>
              )}
              {item.size && (
                <p className={`${trafficStyls.pfs_9px} fw-700 mb-2 text-truncate`}>{item.size}</p>
              )}
              {item.quantity && (
                <p className={`${trafficStyls.pfs_9px} fw-700 mb-0 text-truncate mt-auto`}>
                  {item.quantity}
                </p>
              )}
              <p className={`${trafficStyls.fs_13px} fw-800 mb-0 text-truncate`}>
                {isNaN(Number(item.cost)) ? (
                  <>별도안내</>
                ) : (
                  <>
                    {item.period} {item.cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                  </>
                )}
              </p>
            </>
          )}
          <div className={`d-flex align-items-center ${isMobile ? '' : 'mt-2'}`}>
            <div
              className={`${trafficStyls.fs_11px} fw-800 lh-1 ${styles.hoverUnderline}`}
              style={{ marginRight: '12.66px' }}
              onClick={(e) => {
                e.stopPropagation();
                setDefaultLatLng(item.defaultLatLng);
                setClickedDetail({
                  id: item.id,
                  type: 'clicked',
                  lat: item.defaultLatLng[0],
                  lng: item.defaultLatLng[1],
                });
              }}>
              위치 확인하기
            </div>
            <div className={`${trafficStyls.icon_wrapper} ms-auto`}>
              <img
                src={
                  item.wish
                    ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
                    : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
                }
                alt=""
                onClick={(e) => wishHandler(e, index, item.id, item.wish ? 'delete' : 'add')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
});

export default Billboard;
