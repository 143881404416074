import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { GrClose, GrRefresh } from 'react-icons/gr';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import { GetAccessToken } from '../../api/GetToken';
import { APIURL, DEFAULTURL } from '../../api/apiDefault';
import { Loading } from '../../component/loading';
import './search.css';

const searchDatas = [
  {
    category: 'division',
    name: '구분',
    data: [
      {
        type: 1,
        value: 'billboard',
        label: '빌보드',
        checked: false,
      },
      {
        type: 2,
        value: 'traffic',
        label: '교통',
        checked: false,
      },
      {
        type: 3,
        value: 'shopping',
        label: '쇼핑/외식',
        checked: false,
      },
      {
        type: 4,
        value: 'life',
        label: '생활동선',
        checked: false,
      },
      {
        type: 5,
        value: 'sport',
        label: '스포츠/엔터',
        checked: false,
      },
      {
        type: 6,
        value: 'division_etc',
        label: '기타',
        checked: false,
      },
    ],
  },
  {
    category: 'media_type',
    name: '타입',
    data: [
      {
        value: 'led',
        label: 'LED디스플레이',
        checked: false,
      },
      {
        value: 'lcd_pannel',
        label: 'LCD패널',
        checked: false,
      },
      {
        value: 'lcd_multi',
        label: 'LCD멀티비전',
        checked: false,
      },
      {
        value: 'light_box',
        label: '라이트박스',
        checked: false,
      },
      {
        value: 'electric',
        label: '비조명',
        checked: false,
      },
      {
        value: 'type_sound',
        label: '음성',
        checked: false,
      },
      {
        value: 'type_complex',
        label: '복합형',
        checked: false,
      },
      {
        value: 'type_etc',
        label: '기타',
        checked: false,
      },
    ],
  },
  {
    category: 'form',
    name: '형태',
    data: [
      {
        value: 'x',
        label: '가로형',
        checked: false,
      },
      {
        value: 'y',
        label: '세로형',
        checked: false,
      },
      {
        value: 'shape_complex',
        label: '복합형',
        checked: false,
      },
      {
        value: 'shape_etc',
        label: '기타',
        checked: false,
      },
    ],
  },
  {
    category: 'content',
    name: '컨텐츠',
    data: [
      {
        value: 'video_sound',
        label: '영상(음성지원)',
        checked: false,
      },
      {
        value: 'video',
        label: '영상',
        checked: false,
      },
      {
        value: 'poster',
        label: '모션포스터',
        checked: false,
      },
      {
        value: 'image',
        label: '이미지',
        checked: false,
      },
      {
        value: 'content_sound',
        label: '음성',
        checked: false,
      },
      {
        value: 'content_complex',
        label: '복합형',
        checked: false,
      },
      {
        value: 'content_etc',
        label: '기타',
        checked: false,
      },
    ],
  },
  {
    category: 'location',
    name: '장소',
    data: [
      {
        value: 'inside',
        label: '실내',
        checked: false,
      },
      {
        value: 'outside',
        label: '실외',
        checked: false,
      },
    ],
  },
];

const Search = ({ ...props }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { userInfo } = props;
  const params = new URLSearchParams(window.location.search);
  let value = params.get('v');
  let bill = params.get('b');
  const accessToken = GetAccessToken();
  const navigate = useNavigate();
  const [search, setSearch] = useState(searchDatas);
  const [searchTitle, setSearchTitle] = useState(value ? value : '');

  const [depth1, setDepth1] = useState([]);

  const [mediaData, setMediaData] = useState([]);

  const subCheck = (e) => {
    const _this = e.target;
    const targetObject = search.find((item) => item.category === _this.value);
    const updatedSearch = targetObject.data.map((item) =>
      item.value === _this.id
        ? {
            ...item,
            checked: _this.checked,
          }
        : item,
    );
    const resultSearch = search.map((item) =>
      item.category === _this.value
        ? {
            ...item,
            data: updatedSearch,
          }
        : item,
    );
    setSearch(resultSearch);

    searchSubmit(null, resultSearch);
  };
  const navSearchResult = () => {
    const favorite = {
      category: 'favorite',
      name: '즐겨찾기',
      data: [
        {
          value: 'is_favorite',
          label: '즐겨찾는 매체만',
          checked: false,
        },
      ],
    };
    const updatedSearchDatas = search.map((category) => {
      if (category.category === 'favorite') {
        return category;
      }
      const updatedCategory = { ...category }; // 현재 카테고리 객체를 복사

      updatedCategory.checked = true; // checked 값을 true로 설정

      // 데이터 배열 내 각 객체의 checked 값을 true로 설정
      updatedCategory.data = updatedCategory.data.map((dataItem) => ({
        ...dataItem,
        checked: true,
      }));

      return updatedCategory; // 변경된 카테고리 객체 반환
    });
    setSearch([...updatedSearchDatas, favorite]);
    setSearchTitle(value);
    // searchSubmit(null, [...updatedSearchDatas, favorite]);
  };
  useEffect(() => {
    axios
      .get(`https://www.rialto.kr:8000/API/category/depth/1`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          const depthData = res.data.filter((item) => item.is_admin !== 1);
          const newDepthData = depthData.map((item) => item.id);
          setDepth1(newDepthData);

          const searchDepthData = depthData.map((item) => {
            return {
              type: item.id,
              value: item.name,
              label: item.name,
              checked: false,
            };
          });

          let resultSearchData = search.map((categoryData) => {
            if (categoryData.category === 'division') {
              return {
                ...categoryData,
                data: searchDepthData,
              };
            }
            return categoryData;
          });
          console.log(resultSearchData);
          if (accessToken) {
            const favorite = {
              category: 'favorite',
              name: '즐겨찾기',
              checked: false,
              data: [
                {
                  value: 'is_favorite',
                  label: '즐겨찾는 매체만',
                  checked: false,
                },
              ],
            };
            resultSearchData = [...resultSearchData, favorite];

            resultSearchData = resultSearchData.map((category) => {
              if (category.category === 'favorite') {
                return category;
              }
              const updatedCategory = { ...category }; // 현재 카테고리 객체를 복사

              updatedCategory.checked = false; // checked 값을 true로 설정

              // 데이터 배열 내 각 객체의 checked 값을 true로 설정
              updatedCategory.data = updatedCategory.data.map((dataItem) => ({
                ...dataItem,
                checked: false,
              }));

              return updatedCategory; // 변경된 카테고리 객체 반환
            });
            // setSearch([...search, favorite]);
          }

          if (bill) {
            resultSearchData = resultSearchData.map((item) => {
              if (item.category === 'division') {
                // "division" 카테고리인 경우
                const newData = item.data.map((dataItem) => {
                  if (dataItem.value === '빌보드') {
                    // "빌보드"인 경우
                    return {
                      ...dataItem,
                      checked: true, // "빌보드"는 checked를 true로 유지
                    };
                  } else {
                    // "빌보드"가 아닌 경우
                    return {
                      ...dataItem,
                      checked: false, // 나머지 항목들은 checked를 false로 변경
                    };
                  }
                });

                return {
                  ...item,
                  data: newData,
                };
              } else {
                // "division"이 아닌 경우, 다른 카테고리는 그대로 유지
                const newData = item.data.map((dataItem) => ({
                  ...dataItem,
                  checked: false,
                }));

                return {
                  ...item,
                  data: newData,
                };
              }
            });
          }
          console.log(resultSearchData);
          setSearch(resultSearchData);

          searchSubmit(null, resultSearchData);
        }
      })
      .catch((err) => console.log(err));

    axios
      .get(`${APIURL}mediadata`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setMediaData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  // useEffect(() => {
  //   value && navSearchResult();
  // }, [value]);

  const [pageItems, setPageItems] = useState([]);

  // 검색
  const searchSubmit = (e, _search, _reset) => {
    // navigate('/search');
    console.log(_search);
    let _resultSearch = _search ? _search : search;
    let totalCount = 0;

    for (const item of _resultSearch) {
      if (item.checked === true) {
        totalCount++;
      }

      if (item.data && Array.isArray(item.data)) {
        for (const subItem of item.data) {
          if (subItem.checked === true) {
            totalCount++;
          }
        }
      }
    }

    // if (totalCount === 0 && !searchTitle) {
    //   Swal.fire({
    //     icon: 'error',
    //     iconColor: '#971b4d',
    //     text: '검색할 매체를 입력/선택해 주세요.',
    //     confirmButtonText: '확인',
    //     confirmButtonColor: '#971b4d',
    //   });
    //   return;
    // }
    let newSearch = [];
    if (totalCount === 0) {
      newSearch = _resultSearch.map((category) => ({
        category: category.category,
        data: category.data.map((item) =>
          category.category === 'division' ? item.type : item.label,
        ),
      }));
    } else {
      newSearch = _resultSearch.map((category) => ({
        category: category.category,
        data: category.data
          .filter((item) => item.checked === true)
          .map((item) => (category.category === 'division' ? item.type : item.label)),
      }));
    }

    console.log(newSearch);
    // return;

    if (newSearch[0].data.length === 0) {
      // Swal.fire({
      //   icon: 'error',
      //   iconColor: '#971b4d',
      //   text: '구분을 선택해 주세요.',
      //   confirmButtonText: '확인',
      //   confirmButtonColor: '#971b4d',
      // });
      // return;
      newSearch[0].data = depth1;
    }
    setResult(true);
    setLoading(false);

    if (accessToken) {
      axios
        .get(`${APIURL}favorite/my/media`, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) setWishList(res.data);
        })
        .catch((err) => console.log(err));
    }
    const _data = {
      name: _reset ? '' : searchTitle,
      category: newSearch[0].data.join(),
      media_type: JSON.stringify(newSearch[1].data),
      form: JSON.stringify(newSearch[2].data),
      content: JSON.stringify(newSearch[3].data),
      location: JSON.stringify(newSearch[4].data),
    };

    if (accessToken) {
      _data.token = accessToken;

      if (_resultSearch[5].data[0].checked === true) {
        _data.is_favorite = true;
      } else {
        _data.is_favorite = false;
      }
    } else {
      _data.is_favorite = false;
    }
    axios
      .post(`${APIURL}media/search/all`, _data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(true);

          let filteredData = filterEmptyContent(res.data);
          console.log(filteredData);
          filteredData = filteredData.sort((a, b) => a.sort - b.sort);
          for (const category1 of filteredData) {
            if (category1.content.constructor === Array) {
              category1.content = category1.content.sort((a, b) => a.sort - b.sort);
              for (const category2 of category1.content) {
                if (category2.content.constructor === Array) {
                  category2.content = category2.content.sort((a, b) => a.sort - b.sort);
                  for (const category3 of category2.content) {
                    if (category3.content.constructor === Array) {
                      category3.content = category3.content.sort((a, b) => a.sort - b.sort);
                      for (const category4 of category3.content) {
                        if (category4.content.constructor === Array) {
                          category4.content = category4.content.sort((a, b) => a.sort - b.sort);
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          setPageItems(filteredData);
          // setPageItems(res.data);
        }
      })
      .catch((err) => console.log(err));
    // setResult(true);
  };
  console.log(pageItems);
  function filterEmptyContent(data) {
    return data.filter((item) => {
      if (item.content && Array.isArray(item.content)) {
        item.content = filterEmptyContent(item.content);
      }
      return item.content.length > 0;
    });
  }

  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);

  // 좋아요 버튼
  const handleWish = () => {
    console.log('wish');
  };

  /** 아이콘 변경 */
  const categoryIcon = (_id) => {
    switch (Number(_id)) {
      case 1:
        return 'billboard';
      case 2:
        return 'traffic';
      case 3:
        return 'shopping';
      case 4:
        return 'life';
      case 5:
        return 'sport';
      default:
        return 'etc';
    }
  };

  const [wishList, setWishList] = useState([]);
  /** 매체 아이템 */
  const RenderItems = ({ ...props }) => {
    const { data, category, isMobile } = props;
    const handleWish = (e, status) => {
      e.stopPropagation();
      e.preventDefault();

      if (!accessToken) {
        navigate('/login');
        return;
      }

      if (!status) {
        const _data = {
          media_id: data.id,
        };
        axios
          .post(`${APIURL}favorite`, _data, {
            headers: {
              Authorization: accessToken,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setWishList([...wishList, data.id]);
            }
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .delete(`${APIURL}favorite/${data.id}`, {
            headers: {
              Authorization: accessToken,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              const updatedWishList = wishList.filter((itemId) => itemId !== data.id);
              setWishList(updatedWishList);
            }
          })
          .catch((err) => console.log(err));
      }
    };

    const [nav, setNav] = useState('/');
    const navigateList = () => {
      axios
        .get(`${APIURL}category/one/${data.category_id}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.depth === 3) {
              // navigate(
              //   `/${category}Detail?${category === 'billboard' ? 'id' : 'idx'}=${data.id}${category !== 'billboard' ? '&id=' + res.data.id : ''
              //   }`,
              // );
              setNav(
                `${
                  category === 'billboard'
                    ? `/${category}Detail?id=${res.data.id}&idx=${data.id}`
                    : `/${category}Detail?idx=${data.id}&id${res.data.id}`
                }`,
              );
            } else {
              axios
                .get(`${APIURL}category/one/${res.data.parent_id}`)
                .then((res2) => {
                  if (res2.status === 200) {
                    // navigate(
                    //   `/${category}Detail?${category === 'billboard' ? 'id' : 'idx'}=${data.id}${category !== 'billboard' ? '&id=' + res2.data.id : ''
                    //   }`,
                    // );
                    setNav(
                      `${
                        category === 'billboard'
                          ? `/${category}Detail?id=${res.data.id}&idx=${data.id}`
                          : `/${category}Detail?idx=${data.id}&id${res.data.id}`
                      }`,
                    );
                  }
                })
                .catch((err) => console.log(err));
            }
          }
        })
        .catch((err) => console.log(err));
    };

    const _periods = data.option[0]?.period.split(',') ?? '';
    const _period =
      _periods[0] === '1개월'
        ? '월'
        : _periods[0] === '1주'
        ? '주'
        : _periods[0] === '1일'
        ? '일'
        : _periods[0] === '1회'
        ? '회'
        : _periods[0] ?? '';
    return (
      <Link
        to={nav}
        target="_blank"
        onLoad={navigateList}
        className="text-black result-box pointer pb-4 col-lg-3 col-6">
        <div className="result-img">
          {data.file_data[0] && (
            <img src={`${DEFAULTURL}image/${data.file_data[0]['content']}`} alt="" />
          )}
        </div>
        <div className="result-content">
          <div className="d-flex align-items-center justify-content-between result-title-flex">
            <div className="result-title-text fw-800 text-ellipsis">{data.media_name}</div>
            {!isMobile && (
              <div className="d-flex pointer ms-3">
                <img
                  onClick={(e) => {
                    handleWish(e, wishList.includes(data.id));
                  }}
                  className="result-icon"
                  src={
                    wishList.includes(data.id)
                      ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
                      : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
                  }
                  alt=""
                />
              </div>
            )}
          </div>
          <div className="result-sub-text result-sub-type-text fw-400 text-ellipsis">
            {data.media_type}
          </div>
          <div
            className={`${
              isMobile && 'pt-1'
            } result-sub-text result-sub-type2-text fw-400 text-ellipsis d-flex`}>
            {/* {data.option[0]?.name} */}
            {data.option.map((sortItem, sortIndex) => (
              <div key={'option_sort_' + sortIndex}>{sortItem.sort === 0 && sortItem?.name}</div>
            ))}
            {!isMobile && <span className="ms-2">{data.option[0]?.amount?.split(',')[0]}</span>}
          </div>
          <div className="result-price-flex-box d-flex justify-content-between ">
            <div className="fw-800 result-price-text result-price-mobile-text text-ellipsis">
              {isNaN(data.option[0]?.price) && data.option[0]?.price}
              {!isNaN(data.option[0]?.price) && (
                <div>
                  {_period} {Number(data.option[0]?.price).toLocaleString()}원
                </div>
              )}
            </div>
            {isMobile && (
              <div className="d-flex pointer ms-3">
                <img
                  onClick={(e) => {
                    handleWish(e, wishList.includes(data.id));
                  }}
                  className="result-icon"
                  src={
                    wishList.includes(data.id)
                      ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
                      : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
                  }
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </Link>
    );
  };
  const toTop = () => {
    scroll_container.current?.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
    // navigate(`${location.pathname}`)
  };
  const scroll_container = useRef();

  // 초기화
  const handleReset = () => {
    setSearchTitle('');

    const updatedSearchDatas = search.map((category) => ({
      ...category,
      data: category.data.map((item) => ({
        ...item,
        checked: false,
      })),
    }));
    setSearch(updatedSearchDatas);
    // setPageItems([]);
    searchSubmit(null, updatedSearchDatas, 'reset');
  };

  const [popShow, setPopShow] = useState(false);
  const [pop, setPop] = useState({
    top: 0,
    left: 0,
  });
  const [popInfo, setPopInfo] = useState({
    title: '',
    content: '',
    image: '',
  });

  const subTitleOffset = (e, _category) => {
    e.stopPropagation();

    const _this = e.target;

    setPop({
      top: _this.offsetTop + (isMobile ? 17 : 27),
      left: _this.offsetLeft,
    });
    if (mediaData[_category]) {
      const currentName = e.target.innerText;
      const currentMediaData = mediaData[_category];

      const resultMediaData = currentMediaData.filter((item) => item.title === currentName);

      if (resultMediaData.length > 0) {
        setPopInfo({
          content: resultMediaData[0].content,
          title: _this.innerHTML,
          image: resultMediaData[0].image,
        });
        setPopShow(true);
      } else {
        setPopShow(false);
        setPopInfo({ content: '데이터가 없습니다.', title: _this.innerHTML });
      }
    } else {
      setPopShow(false);
      setPopInfo({ content: '데이터가 없습니다.', title: _this.innerHTML });
    }
    // rect.top - (isMobile ? 59 : 88)
    //  - (aside ? aside.offsetWidth + 50 : 20)
  };

  const searchKeyDown = (e) => {
    if (e.code === 'Enter') {
      searchSubmit(null);
    }
  };

  const popShowReset = () => {
    setPopShow(false);
    // setPopInfo({ content: '', image: '', title: '' })
  };

  return (
    <div className="my-container bg-white position-relative" onClick={popShowReset}>
      <div className="bg-white search-bg-box">
        <div className="position-relative search-container scroll" ref={scroll_container}>
          <div
            className={`search-pop ${popShow ? '' : 'd-none'}`}
            style={{ top: pop.top + 'px', left: pop.left + 'px' }}>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ gap: '0 19px' }}>
              <div className="pop-title fw-700 text-nowrap">{popInfo.title}</div>
              <img
                src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`}
                onClick={popShowReset}
                className="pointer"
                alt=""
              />
            </div>
            <div className="pop-box">
              {popInfo.image && (
                <img
                  src={`${DEFAULTURL}image/site/${popInfo.image}`}
                  className="pop-box-img"
                  alt=""
                />
              )}
              <div className="pop-content fw-700" style={{ whiteSpace: 'pre-wrap' }}>
                {popInfo.content}
              </div>
            </div>
          </div>
          <div className="bg-white w-100 search-full-box">
            <div>
              <div className="d-flex align-items-center search-title-content">
                <img
                  src={`${process.env.PUBLIC_URL}/image/icon/search.svg`}
                  className="search-title-icon"
                  alt=""
                />
                <div className="fw-800 search-title">상세 검색</div>
              </div>
              <div>
                <div className="search-flex-box">
                  <div className="d-flex align-items-center search-title-box border-bottom-0">
                    <label
                      className="search-reset-text search-title-text search-name-text fw-800 w-100 pointer"
                      onClick={handleReset}>
                      초기화
                      <GrRefresh className="ms-1" />
                    </label>
                  </div>
                  <div className="w-100 search-content-box border-bottom-0 align-items-center px-3">
                    <div className="d-flex search-input position-relative">
                      <input
                        type="text"
                        className="border-0 search-input ps-3 pe-5 search-input-text"
                        value={searchTitle}
                        onChange={(e) => setSearchTitle(e.target.value)}
                        placeholder="매체 이름"
                        onKeyDown={searchKeyDown}
                      />
                      <div
                        className="position-absolute top-50 translate-middle-y pointer"
                        style={{ right: '10px' }}
                        onClick={() => setSearchTitle('')}>
                        <GrClose size={10} />
                      </div>
                    </div>
                  </div>
                </div>

                {search.map((item, index) => (
                  <div key={`search_${index}`} className="search-nth">
                    {item.category !== 'favorite' && (
                      <div className="search-flex-box">
                        <div className="search-sub-title-box">
                          <div
                            className={`d-flex align-items-center search-sub-bottom-border ${
                              item.name === '장소' ? 'search-last-border' : ''
                            }`}>
                            <label className="search-title-text fw-800 w-100">{item.name}</label>
                          </div>
                        </div>
                        <div
                          className={`w-100 d-flex search-content-box px-3`}
                          style={{ minHeight: '38px' }}>
                          <div
                            className={`w-100 d-flex search-content-border-box py-1 flex-wrap  ${
                              item.name === '장소' ? 'search-last-content-border' : ''
                            }`}>
                            {search[index].data.map((item2, index2) => (
                              <div
                                className="d-flex align-items-center search-label-width "
                                key={`search_21_${index2}`}>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={item2.value}
                                    className="d-none search-checkbox"
                                    checked={item2.checked}
                                    onChange={subCheck}
                                    value={item.category}
                                  />
                                  <label
                                    htmlFor={item2.value}
                                    className="search-check-label"></label>
                                </div>
                                <label
                                  onClick={(e) => subTitleOffset(e, item.category)}
                                  className={`fw-700 text-nowrap sub-title w-100 ${
                                    item.name === '구분' ? '' : 'hover-help'
                                  }`}>
                                  {item2.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {item.category === 'favorite' && (
                      <div className="search-flex-box search-favor-box">
                        <div className="search-sub-title-box">
                          <div className="d-flex align-items-center search-sub-bottom-border search-favor-title ">
                            <label className="search-title-text fw-800 w-100">{item.name}</label>
                          </div>
                        </div>
                        <div
                          className="w-100 d-flex search-content-box py-1 px-3 border-0"
                          style={{ minHeight: '38px' }}>
                          {search[index].data.map((item2, index2) => (
                            <div
                              className="d-flex align-items-center search-label-width "
                              key={`search_22_${index2}`}>
                              <div className="d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  id={item2.value}
                                  className="d-none search-checkbox"
                                  checked={item2.checked}
                                  onChange={subCheck}
                                  value={item.category}
                                />
                                <label htmlFor={item2.value} className="search-check-label"></label>
                              </div>
                              <label
                                onClick={(e) => subTitleOffset(e, item.category)}
                                className="fw-700 text-nowrap sub-title w-100">
                                {item2.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                {/* <div style={{ marginTop: '12px' }}>
                  <div className="search-btn text-white fw-700 pointer" onClick={searchSubmit}>
                    검 색
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div>
            {result && (
              <>
                {loading &&
                  pageItems.length > 0 &&
                  pageItems.map((item) => (
                    <div className="result-container" key={'category_' + item.id}>
                      <div className="d-flex align-items-center justify-content-between sticky-title search-result-category bg-white w-100 search-sticky-title-box">
                        <div className="d-flex align-items-center ">
                          <img
                            src={`${process.env.PUBLIC_URL}/image/icon/${categoryIcon(
                              item.id,
                            )}.svg`}
                            className="search-title-icon"
                            alt=""
                          />
                          <div className="fw-800 search-title">{item.title}</div>
                        </div>
                        <img
                          className="pointer"
                          src={`${process.env.PUBLIC_URL}/image/icon/toTop.svg`}
                          onClick={toTop}
                          alt=""
                        />
                      </div>
                      {item.content.map((item2) =>
                        item2.option ? (
                          <RenderItems
                            data={item2}
                            category={categoryIcon(item.id)}
                            isMobile={isMobile}
                            key={'item2_' + item2.id}
                          />
                        ) : (
                          <>
                            {item2.content.length > 0 && (
                              <div className="mb-3" key={`item2_${item2.id}`}>
                                <div className="search-item2-title fw-800 sticky-item2 search-sticky-title-box">
                                  {item2.title}
                                </div>
                                {item2.content.map((item3) =>
                                  item3.option ? (
                                    <RenderItems
                                      key={'item3_' + item3.id}
                                      data={item3}
                                      category={categoryIcon(item.id)}
                                      isMobile={isMobile}
                                    />
                                  ) : (
                                    <div className="mb-4" key={`item3_${item3.id}`}>
                                      <div className="search-item3-title fw-800 main sticky-item3 search-sticky-title-box">
                                        {item3.title}
                                      </div>
                                      <div className="search-sticky-title-box">
                                        <div
                                          className={
                                            item3.content.some((item4) => item4.option) ? 'row' : ''
                                          }>
                                          {item3.content.map((item4) =>
                                            item4.option ? (
                                              <RenderItems
                                                key={'item4_' + item4.id}
                                                data={item4}
                                                category={categoryIcon(item.id)}
                                                isMobile={isMobile}
                                              />
                                            ) : (
                                              <div>
                                                <div
                                                  key={`item4_${item4.id}`}
                                                  className="fw-700 fs-11px sticky-item4">
                                                  {item4.title}
                                                </div>
                                                <div className="row dd">
                                                  {item4.content.map(
                                                    (item5) =>
                                                      item5.option && (
                                                        <RenderItems
                                                          key={'item5_' + item5.id}
                                                          data={item5}
                                                          category={categoryIcon(item.id)}
                                                          isMobile={isMobile}
                                                        />
                                                      ),
                                                  )}
                                                </div>
                                              </div>
                                            ),
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                            )}
                          </>
                        ),
                      )}
                    </div>
                  ))}
                {loading && pageItems.length === 0 && (
                  <div className="fw-800 fs-12px no-search">검색 결과가 없습니다.</div>
                )}
                {!loading && <Loading />}
              </>
            )}
          </div>

          {isMobile && <div style={{ paddingTop: '50px' }}></div>}
        </div>
      </div>
    </div>
    // <div className="rialto-container">
    //   <Nav />
    //   <div className="rialto-content-box d-flex">
    //     <Aside />
    //   </div>

    // </div >
  );
};
export default Search;
